<script>
	import { facebook } from '$lib/env.js';
</script>

<svelte:head>
	<link rel="preconnect" href="https://connect.facebook.net" crossorigin />
	<script>
		!(function (f, b, e, v, n, t, s) {
			if (f.fbq) return;
			n = f.fbq = function () {
				n.callMethod
					? n.callMethod.apply(n, arguments)
					: n.queue.push(arguments);
			};
			if (!f._fbq) f._fbq = n;
			n.push = n;
			n.loaded = !0;
			n.version = '2.0';
			n.queue = [];
			t = b.createElement(e);
			t.async = !0;
			t.src = v;
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(
			window,
			document,
			'script',
			'https://connect.facebook.net/en_US/fbevents.js',
		);
		fbq('init', '516648621844325'); //importing from env.js not working for some reason, so hardcoded
		fbq('track', 'PageView');
	</script>
	<noscript>
		<img
			height="1"
			width="1"
			src={`https://www.facebook.com/tr?id=${facebook.pixelId}&ev=PageView&noscript=1`}
			alt="noscript"
			style="position:fixed;pointer-events:none;opacity:0;padding:0;margin:0;height:0;width:0;"
		/>
	</noscript>
	<!-- End Facebook Pixel Code -->
</svelte:head>
