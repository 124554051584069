<script>
	import { page } from '$app/stores';
	import {
		getGbraidClickId,
		getGclid,
		getWbraidClickId,
	} from '$lib/Components/Pixels/googleAds.js';

	function getClickIds(url) {
		getGclid(url);
		getGbraidClickId(url);
		getWbraidClickId(url);
	}
	$: getClickIds($page.url);
</script>

<svelte:head>
	<link rel="preconnect" href="https://www.google-analytics.com" crossorigin />
	<link rel="preconnect" href="https://stats.g.doubleclick.net" crossorigin />
	<script
		async
		src="https://www.googletagmanager.com/gtag/js?id=G-VSR84W9RWG"
	></script>
	<script>
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			dataLayer.push(arguments);
		}
		gtag('js', new Date());
		gtag('config', 'G-VSR84W9RWG'); //importing from env.js not working for some reason, so hardcoded
	</script>
</svelte:head>
