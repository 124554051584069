<script>
	import { afterNavigate } from '$app/navigation';

	afterNavigate(() => {
		const BROWSER = typeof window !== 'undefined';
		if (!BROWSER) {
			return;
		}
		if (typeof window.saq !== 'undefined') {
			window.saq('ts', 'yx_j3wgAt4V_yhvtMskVyw');
		}
	});
</script>

<svelte:head>
	<link rel="preconnect" href="https://tags.srv.stackadapt.com" crossorigin />
	<script>
		!(function (s, a, e, v, n, t, z) {
			if (s.saq) return;
			n = s.saq = function () {
				n.callMethod
					? n.callMethod.apply(n, arguments)
					: n.queue.push(arguments);
			};
			if (!s._saq) s._saq = n;
			n.push = n;
			n.loaded = !0;
			n.version = '1.0';
			n.queue = [];
			t = a.createElement(e);
			t.async = !0;
			t.src = v;
			z = a.getElementsByTagName(e)[0];
			z.parentNode.insertBefore(t, z);
		})(window, document, 'script', 'https://tags.srv.stackadapt.com/events.js');
	</script>
	<noscript
		><img
			src="https://tags.srv.stackadapt.com/conv?cid=yx_j3wgAt4V_yhvtMskVyw"
			width="1"
			height="1"
			alt=""
			style="position:fixed;pointer-events:none;opacity:0;padding:0;margin:0;height:0;width:0;"
		/></noscript
	>
</svelte:head>
