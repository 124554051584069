<script>
	import { page } from '$app/stores';
	import pageMap from '$lib/pageMap';

	$: paths = $page.url.pathname.split('/').filter(Boolean);
	$: pathsFullUrl = getPathUrls(paths);

	// convert separated path section array to path array containing full path url
	function getPathUrls(paths) {
		let newPaths = [];
		paths.forEach((path, i) => {
			newPaths.push(`/${[...paths.slice(0, i + 1)].join('/')}`);
		});
		return newPaths;
	}
</script>

<nav aria-label="Breadcrumb" class="hidden md:block">
	<ol
		class="flex list-outside flex-row flex-wrap items-center justify-center gap-x-2 marker:text-indow-white"
		style="list-style-type: '/'"
	>
		<li class="p-2 first:list-none">
			<a href="/" class="no-underline">Home</a>
		</li>
		{#each pathsFullUrl as path, i}
			<li class="p-2">
				{#if i === pathsFullUrl.length - 1}
					<span aria-current="location">
						{pageMap[path] ?? paths[i]}
					</span>
				{:else}
					<a href={path}>
						{pageMap[path] ?? paths[i]}
					</a>
				{/if}
			</li>
		{/each}
	</ol>
</nav>
