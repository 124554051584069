//The this links file differs slightly from the
//original file found in the "Header" folder.
//The premise is the same, though.
//These are the SVG logos for the social media icons.
import facebook from './images/facebook.svg';
import instagram from './images/instagram.svg';
import linkedin from './images/linkedin.svg';
import pinterest from './images/pinterest.svg';
// import rss from './images/rss.svg';
import twitter from './images/twitter.svg';
import youtube from './images/youtube.svg';

export const links = [
	//The first column of links...
	{
		name: 'PRODUCTS',
		href: '/solutions',
		children: [
			{
				name: 'Drafts & Savings',
				href: '/solutions/insulating-drafty-windows',
			},
			{
				name: 'Noise Reduction',
				href: '/solutions/window-noise-reduction',
			},
			{ name: 'Light & UV', href: '/solutions/light-uv' },
			{
				name: 'Speciality Shapes',
				href: '/resources/blog/insulate-specialty-windows',
			},
			{
				name: 'Commercial Projects',
				href: '/commercial-storm-windows',
			},
			{
				name: 'All Product Types',
				href: '/custom-storm-windows',
			},
			{ name: 'All Product Data', href: '/performance' },
		],
	},

	//The second column of links...
	{
		name: 'RESOURCES',
		href: '/resources',
		children: [
			{ name: 'FAQ', href: '/resources/faq' },
			{ name: 'Case Studies', href: '/resources/case-studies' },
			{ name: 'Featured Articles', href: '/resources/featured-articles' },
			{
				name: 'Historic Preservation',
				href: '/resources/historic-preservation',
			},
			{
				name: 'Window Installation Guides',
				href: '/resources/window-guides',
			},
			{
				name: 'Measure & Installation Support',
				href: '/about-indow/how-to-order/measure',
			},
		],
	},

	//The third column of links...
	{
		name: 'ABOUT INDOW',
		href: '/about-indow',
		children: [
			{ name: 'Press / Awards', href: '/about-indow/media' },
			{ name: 'Careers', href: '/about-indow/careers' },
			{
				name: 'Contractor Discount Program',
				href: '/about-indow/dealer-program',
			},
			{ name: 'Site Map', href: '/site-map' },
			{ name: 'Terms of Sale', href: '/warranties/terms-of-sale' },
			{ name: 'Privacy Policy', href: '/privacy-policy' },
		],
	},

	//The fourth column of links...
	{
		name: 'CONTACT US',
		href: '/about-indow/contact',
		children: [
			{ name: 'How to Order', href: '/about-indow/how-to-order' },
			{ name: 'Get a Free Estimate', href: '/indow-cost' },
			{ name: '503-284-2260', href: 'tel:503-284-2260' },
			{
				name: 'comfort@indowwindows.com',
				href: 'mailto:comfort@indowwindows.com',
			},
		],
	},
];

export const social = [
	{
		name: 'Facebook',
		href: 'https://www.facebook.com/indowwindows',
		img: facebook,
	},
	{
		name: 'LinkedIn',
		href: 'https://www.linkedin.com/company/indow-windows',
		img: linkedin,
	},
	{
		name: 'Instagram',
		href: 'https://www.instagram.com/indowwindows',
		img: instagram,
	},
	{
		name: 'Twitter',
		href: 'https://twitter.com/indowwindows',
		img: twitter,
	},
	{
		name: 'Youtube',
		href: 'https://www.youtube.com/user/IndowWindows',
		img: youtube,
	},
	{
		name: 'Pinterest',
		href: 'https://www.pinterest.com/indowwindows',
		img: pinterest,
	},
];
