<script>
	import Bing from './Bing.svelte';
	import Facebook from './Facebook.svelte';
	import GoogleAnalytics from './GoogleAnalytics.svelte';
	import PiwikPro from './PiwikPro.svelte';
	import Quora from './Quora.svelte';
	import Reddit from './Reddit.svelte';
	import StackAdapt from './StackAdapt.svelte';

	import { PUBLIC_ENV } from '$env/static/public';
</script>

{#if PUBLIC_ENV == 'production'}
	<Bing />
	<Facebook />
	<GoogleAnalytics />
	<Quora />
	<StackAdapt />
	<Reddit />
	<PiwikPro />
{/if}
