<script lang="ts">
	import { page } from '$app/stores';
	import * as pageMap from '$lib/pageMap.json';

	import type { Graph } from 'schema-dts';

	// search disabled
	// type QueryAction = SearchAction & {
	// 	'query-input': string;
	// };
	// let searchActionSchema: QueryAction;
	// $: searchActionSchema = {
	// 	'@type': 'SearchAction',
	// 	target: {
	// 		'@type': 'EntryPoint',
	// 		urlTemplate: `${$page?.url?.origin}?s={search_term_string}`,
	// 	},
	// 	'query-input': 'required name=search_term_string',
	// };

	let schema: Graph;
	$: schema = {
		'@context': 'https://schema.org',
		'@graph': [
			{
				'@type': 'WebPage',
				'@id': `${$page?.url?.origin}${
					$page?.url?.pathname?.endsWith('/')
						? $page?.url?.pathname?.slice(0, -1)
						: $page?.url?.pathname ?? ''
				}`,
				url: `${$page?.url?.origin}${
					$page?.url?.pathname?.endsWith('/')
						? $page?.url?.pathname?.slice(0, -1)
						: $page?.url?.pathname ?? ''
				}`,
				name: $page?.data?.title ?? $page?.data?.h1 ?? 'Indow Windows',
				isPartOf: {
					'@id': `${$page?.url?.origin}#website`,
				},
				about: {
					'@id': `${$page?.url?.origin}#organization`,
				},
				datePublished: '2020-02-29T19:36:47+00:00',
				dateModified: '2023-05-25T02:45:34+00:00',
				description: $page?.data?.description ?? '',
				breadcrumb: {
					'@id': `${$page?.url?.origin}${
						$page?.url?.pathname?.endsWith('/')
							? $page?.url?.pathname?.slice(0, -1)
							: $page?.url?.pathname ?? ''
					}#breadcrumb`,
				},
				inLanguage: 'en-US',
				potentialAction: [
					{
						'@type': 'ReadAction',
						target: `${$page?.url?.origin}${
							$page?.url?.pathname?.endsWith('/')
								? $page?.url?.pathname?.slice(0, -1)
								: $page?.url?.pathname ?? ''
						}`,
					},
				],
			},
			{
				'@type': 'BreadcrumbList',
				'@id': `${$page?.url?.origin}${
					$page?.url?.pathname?.endsWith('/')
						? $page?.url?.pathname?.slice(0, -1)
						: $page?.url?.pathname ?? ''
				}#breadcrumb`,
				itemListElement: $page?.url?.pathname
					?.split('/')
					.filter(Boolean)
					.reduce(
						(acc, path, i, paths) => {
							acc.push({
								'@type': 'ListItem',
								position: i + 2,
								item: {
									'@type': 'WebPage',
									'@id': `${$page?.url?.origin}/${[
										...paths.slice(0, i + 1),
									].join('/')}`,
									name:
										pageMap[`/${[...paths.slice(0, i + 1)].join('/')}`] ?? path,
								},
							});
							return acc;
						},
						[
							{
								'@type': 'ListItem',
								position: 1,
								item: {
									'@type': 'WebPage',
									'@id': $page?.url?.origin,
									name: 'Home',
								},
							},
						],
					),
			},
			{
				'@type': 'WebSite',
				'@id': `${$page?.url?.origin}#website`,
				url: $page?.url?.origin,
				name: 'Indow',
				description: 'Custom Window Inserts',
				publisher: {
					'@id': `${$page?.url?.origin}#organization`,
				},
				// potentialAction: [searchActionSchema], // search disabled
				inLanguage: 'en-US',
			},
			{
				'@type': 'Organization',
				'@id': `${$page?.url?.origin}#organization`,
				name: 'Indow',
				url: `${$page?.url?.origin}`,
				telephone: '(503) 284-2260',
				address: {
					'@type': 'PostalAddress',
					streetAddress: '6427 NE 59th Pl',
					addressLocality: 'Portland',
					addressRegion: 'OR',
					postalCode: '97218',
					addressCountry: 'US',
				},
				logo: {
					'@type': 'ImageObject',
					'@id': `${$page?.url?.origin}#/schema/logo/image`,
					url: `${$page?.url?.origin}/images/indow-logo.svg`,
					contentUrl: `${$page?.url?.origin}/images/indow-logo.svg`,
					caption: 'Indow',
					width: '300',
					height: '107',
				},
				image: {
					'@id': `${$page?.url?.origin}#/schema/logo/image`,
				},
				sameAs: [
					'https://www.instagram.com/indowwindows',
					'https://www.linkedin.com/company/indow-windows',
					'https://www.pinterest.com/indowwindows',
					'https://www.youtube.com/indowwindows',
					'https://www.facebook.com/indowwindows',
					'https://twitter.com/indowwindows',
				],
			},
		],
	};
</script>

<svelte:head>
	<!-- eslint-disable -->
	{@html // prettier-ignore
	'<script type="application/ld+json">' + JSON.stringify(schema, null, 2) + '</script>'}
</svelte:head>
