<script>
	import { afterNavigate } from '$app/navigation';
	import { page } from '$app/stores';

	import { getQuoraClickId } from './quora';

	afterNavigate(() => {
		const BROWSER = typeof window !== 'undefined';
		if (!BROWSER) {
			return;
		}
		if (typeof window.qp !== 'undefined') {
			window.qp('track', 'ViewContent');
		}
	});

	$: getQuoraClickId($page.url);
</script>

<svelte:head>
	<link rel="preconnect" href="https://a.quora.com" crossorigin />
	<script>
		!(function (q, e, v, n, t, s) {
			if (q.qp) return;
			n = q.qp = function () {
				n.qp ? n.qp.apply(n, arguments) : n.queue.push(arguments);
			};
			n.queue = [];
			t = document.createElement(e);
			t.async = !0;
			t.src = v;
			s = document.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(window, 'script', 'https://a.quora.com/qevents.js');
		qp('init', '7c8c003a0a6546e3aeae3b86ca97d53a');
		qp('track', 'ViewContent');
	</script>
</svelte:head>

<noscript
	><img
		height="1"
		width="1"
		alt="noscript"
		src="https://q.quora.com/_/ad/7c8c003a0a6546e3aeae3b86ca97d53a/pixel?tag=ViewContent&noscript=1"
		style="position:fixed;pointer-events:none;opacity:0;padding:0;margin:0;height:0;width:0;"
	/></noscript
>
