<script>
	import { page } from '$app/stores';
	import Section from '$lib/Components/Layout/Section/Section.svelte';
	import pageMap from '$lib/pageMap';

	import Breadcrumbs from './Breadcrumbs.svelte';
	import bg1 from './images/page-title-headers-01.jpg';
	import bg2 from './images/page-title-headers-02.jpg';
	import bg3 from './images/page-title-headers-03.jpg';
	import bg4 from './images/page-title-headers-04.jpg';

	let backgroundImages = new Map([
		['solutions', bg1],
		['commercial', bg2],
		['resources', bg3],
		['about-indow', bg4],
	]);

	$: paths = $page.url.pathname.split('/').filter(Boolean);
	$: pathsFullUrl = getPathUrls(paths);

	// convert separated path section array to path array containing full path url
	function getPathUrls(paths) {
		let newPaths = [];
		paths.forEach((path, i) => {
			newPaths.push(`/${[...paths.slice(0, i + 1)].join('/')}`);
		});
		return newPaths;
	}
</script>

{#if $page?.data?.showTitle}
	<Section
		backgroundColor="blue"
		innerClass={[
			'pt-[clamp(3rem,5vw,6rem)] md:pt-[clamp(2.5rem,5vw,6rem)] pb-[clamp(2.5rem,5vw,6rem)]',
		].join(' ')}
		backgroundImage={backgroundImages.get(
			$page?.url?.pathname?.split('/')?.[1],
		) ?? null}
		loading="eager"
	>
		<h1 class=" font-bold">
			{$page?.data?.h1 ??
				$page?.data?.title ??
				pageMap[pathsFullUrl.slice(-1)] ??
				paths.slice(-1)}
		</h1>
		{#if $page?.data?.breadcrumbs}
			<Breadcrumbs />
		{/if}
	</Section>
{/if}
