import imgCommercial from './images/commercial-white.svg';
import imgPhone from './images/phone-white.svg';
import imgProducts from './images/products-white.svg';
import imgResources from './images/resources-white.svg';

export const links = [
	{
		name: 'Products',
		href: '/solutions',
		img: imgProducts,
		children: [
			{
				name: 'Drafts & Savings',
				href: '/solutions/insulating-drafty-windows',
			},
			{
				name: 'Noise Reduction',
				href: '/solutions/window-noise-reduction',
			},
			{ name: 'Light & UV', href: '/solutions/light-uv' },
			{
				name: 'Speciality Shapes',
				href: '/resources/blog/insulate-specialty-windows',
			},
			{
				name: 'All Product Types',
				href: '/custom-storm-windows',
			},
			{ name: 'All Product Data', href: '/performance' },
		],
	},
	{
		name: 'Commercial Projects',
		href: '/commercial-storm-windows',
		img: imgCommercial,
		children: [
			{
				name: 'Soundproofing Hotel Windows',
				href: '/commercial-storm-windows/hotel-soundproofing',
			},
			{
				name: 'Soundproofing An Office',
				href: '/commercial-storm-windows/office-soundproofing',
			},
			{
				name: 'Historic Building Renovations',
				href: '/commercial-storm-windows/historic-tax-credit',
			},
		],
	},
	{
		name: 'Resources',
		href: '/resources',
		img: imgResources,
		children: [
			{ name: 'FAQ', href: '/resources/faq' },
			{ name: 'Case Studies', href: '/resources/case-studies' },
			{ name: 'Featured Articles', href: '/resources/featured-articles' },
			{
				name: 'Historic Preservation',
				href: '/resources/historic-preservation',
			},
			{
				name: 'Window Installation Guides',
				href: '/resources/window-guides',
			},
			{
				name: 'Measure & Installation Support',
				href: '/about-indow/how-to-order/measure',
			},
		],
	},
	{
		name: 'About',
		href: '/about-indow',
		img: imgProducts,
		children: [
			{ name: 'Our Story', href: '/about-indow' },
			{ name: 'Team', href: '/about-indow/management-team' },
			{ name: 'Press', href: '/about-indow/media' },
			{
				name: 'Careers',
				href: '/about-indow/careers',
			},
		],
	},
	{
		name: 'Call Us at 503.284.2260',
		href: 'tel:503-284-2260',
		img: imgPhone,
		hideDesktop: true,
	},
];
