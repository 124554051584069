<script>
	import { afterNavigate } from '$app/navigation';
	import { page } from '$app/stores';
	import { getRedditClickId } from '$lib/Components/Pixels/reddit';

	afterNavigate(() => {
		const BROWSER = typeof window !== 'undefined';
		if (!BROWSER) {
			return;
		}
		if (typeof window.rdt !== 'undefined') {
			window.rdt('track', 'PageVisit');
		}
	});

	$: getRedditClickId($page.url);
</script>

<svelte:head>
	<script>
		!(function (w, d) {
			if (!w.rdt) {
				var p = (w.rdt = function () {
					p.sendEvent
						? p.sendEvent.apply(p, arguments)
						: p.callQueue.push(arguments);
				});
				p.callQueue = [];
				var t = d.createElement('script');
				(t.src = 'https://www.redditstatic.com/ads/pixel.js'), (t.async = !0);
				var s = d.getElementsByTagName('script')[0];
				s.parentNode.insertBefore(t, s);
			}
		})(window, document);
		rdt('init', 't2_znjfi');
		rdt('track', 'PageVisit');
	</script>
</svelte:head>
